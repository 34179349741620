import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Block } from './styles';

export function Spinner() {
  return (
    <Block>
      <Spin size="large" indicator={<LoadingOutlined spin />} />
    </Block>
  );
}
