import styled from "styled-components";
import { Image, Spin } from "antd";
import Icon from "@ant-design/icons/lib/components/Icon";

export const Container = styled.div`
	width: 100%;
	height: 100%;
`;

export const ImageStyle = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	flex-direction: column;
	border-radius: 5px;
	position: relative;
	padding: 5px;
`;

export const LoadingDiv = styled.div`
	display: flex;
	width: 100%;
	height: 10vw;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		align-items: center;
	}
`;

export const IconStyle = styled(Icon)`
	position: absolute;
	z-index: 100;
	top: 8px;
	right: 8px;
	font-size: 20px;
	img {
		width: 30px !important;
		height: 30px;
	}
`

export const Images = styled(Image)`
	width: 100%;
	height: 10vw !important; 
	object-fit: cover;
	${({ theme }) =>
    theme.media.tablet(
      `
				height: 95px !important;
      `,
    )}
		${({ theme }) =>
    theme.media.mobile(
      `
				height: 85px !important;
			`,
		)}
`;

export const TextGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 49% 49%;
	grid-column-gap: 2%;
	grid-row-gap: 1%;
	padding-top: 2px;
	padding-bottom: 2px;
	justify-content: space-between;
	${({ theme }) =>
    theme.media.tablet(
      `
			display: flex;
			flex-direction: column;
      `,
    )}
			${({ theme }) =>
    theme.media.mobile(
      `

      `,
    )}
`;

export const ContentGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 50% 50%;
	margin-top: 5px;
	padding-left: 5px;
	padding-right: 5px;
	${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 15px;
      `,
    )}
	div {
		display: flex;
		justify-content: start;
		align-items: center;
		font-family: 'Inter medium';
		font-weight: 300;
		font-size: 13px;
		text-align: start;
		color: #ffffff;
		${({ theme }) =>
			theme.media.monitor(
			`
				font-size: 20px;
			`,
			)}
		${({ theme }) =>
			theme.media.mobile(
			`
				font-size: 9.5px;
			`,
		)}
		// 최대 화면 크기가 358px 이하라면 font 크기가 12px로 변경
		@media only screen and (max-width: 358px){
			font-size: 7px !important;
		}
	}
	p{
		font-family: 'Inter medium', 'Suit regular';
		font-weight: 400;
		font-size: 13px;
		text-align: end;
		color: #ffffff;
		display: flex;
		justify-content: end;
		align-items: center;
		${({ theme }) =>
			theme.media.monitor(
			`
				font-size: 20px;
			`,
			)}
		${({ theme }) =>
		theme.media.mobile(
			`
			font-size: 9px;
			`,
		)}
		@media only screen and (max-width: 358px){
			font-size: 7px !important;
		}
	}
`;

export const SpinDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	height: 10vw;
`;

export const LoadingSpinIcon = styled(Spin)`
  .ant-spin-dot-item {
    background-color: black;
  }
	svg{
		color: white;
	}
`;