import baseStyled, { css, CSSProp, ThemedStyledInterface } from 'styled-components';

// 최소 사이즈 기준
const sizes: { [key: string]: number } = {
  mobile: 700,
  tablet: 1024,
  desktop: 1025,
  monitor: 2100,
};

const colors = {
  white: '#ffffff',
  black: '#000000',
  headerBg: '#001529',
};

const secondaryColors = {};

const fontSizes = {
  xsm: '13px',
  sm: '14px',
  md: '15px',
  lg: '18px',
  xl: '30px',
};

type BackQuoteArgs = string[];

interface Media {
  mobile: (...args: BackQuoteArgs) => CSSProp | undefined;
  tablet: (...args: BackQuoteArgs) => CSSProp | undefined;
  desktop: (...args: BackQuoteArgs) => CSSProp | undefined;
  monitor: (...args: BackQuoteArgs) => CSSProp | undefined;
}

const media: Media = {
  mobile: (...args: BackQuoteArgs) =>
    css`
      @media only screen and (max-width: ${sizes.mobile}px) {
        ${args.join('')}
      }
    `,
  tablet: (...args: BackQuoteArgs) =>
    css`
      @media only screen and (max-width: ${sizes.tablet}px) and (min-width: ${sizes.mobile}px) {
        ${args.join('')}
      }
    `,
  desktop: (...args: BackQuoteArgs) =>
    css`
      @media only screen and (min-width: ${sizes.tablet}px) {
        ${args.join('')}
      }
    `,
  monitor: (...args: BackQuoteArgs) =>
    css`
      @media only screen and (min-width: ${sizes.monitor}px) {
        ${args.join('')}
      }
    `,
};

export const theme = {
  colors,
  fontSizes,
  secondaryColors,
  media,
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;

