import styled from "styled-components";
import { Button, List } from "antd";
import { Pagination } from 'antd';

export const ListCss: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  padding: '15px',
  overflow: 'auto',
};

export const MainPageContainer = styled.div`
  width: 88%;
  margin: 0 auto;
  ${({ theme }) =>
    theme.media.mobile(
      `
      width: 92%;
      `,
    )}
`;

export const NonText = styled.div`
    font-family: 'Suit bold';
    font-weight: 700;
    font-size: 1.5vw;
    text-align: center;
    color: rgba(255, 255, 255, 0.71);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ theme }) =>
      theme.media.mobile(
        `
        font-size: 16px;
        height: 70px;
        `,
      )}
`

export const StyledList = styled(List)`
  margin-top: 10px;
  row-gap: 15px;
  .ant-list{
    background-color: transparent !important;
  }
  .ant-list-header {
    padding-block: 0px;
  }
  .ant-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-spin-nested-loading{
    overflow: auto;
    height: 100%;
  }
  .ant-spin-nested-loading::-webkit-scrollbar{
    display: none;
  }
  .ant-list-empty-text{
    height: 350px;
  }
  ${({ theme }) =>
      theme.media.mobile(
      `
        margin-top: 0px;
        padding: 0px !important;
      `,
  )}
`;

export const TestLog = styled.li<{ isVisited: boolean }>`
  background: rgba(255, 255, 255, 0.7);;
  margin-bottom: 15px;
  cursor: pointer;
  width: 100%;
  height: 45px;
  display: grid;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  grid-template-columns: 5% 14% 24% 8% 26% 5% 6% 5%;
  grid-column-gap: 1%;
  border-radius: 5px;
  transition: 0.1s ease all;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.78);
  text-overflow: ellipsis;
  opacity: ${(props) => (props.isVisited ? 0.7 : 1)};
  ${({ theme }) =>
      theme.media.monitor(
      `
        height: 55px;
        grid-template-columns: 5% 12% 21% 9% 28% 5% 6% 6%;
        margin-bottom: 15px;
      `)}
  ${({ theme }) =>
      theme.media.tablet(
      `
        grid-template-columns: 5% 13% 16% 9% 23% 9% 6% 10%;
      `,)}
    ${({ theme }) =>
      theme.media.mobile(
      `
        height: 38px;
        grid-template-columns: 9% 20% 17% 17% 20% 6% 6%;
        grid-column-gap: 1%;
        padding-left: 10px;
        padding-right: 5px;
      `,
      )}
`;

export const TestItem = styled.p`
  text-indent: 5px;
  font-family: 'Suit medium', 'Inter medium', sans-serif;
  color: rgba(0, 0, 0, 0.78);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  overflow: hidden;
  word-break: keep-all;
  font-size: 15px;
  ${({ theme }) =>
    theme.media.monitor(
      `
      text-indent: 15px;
      font-size: 20px;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
      font-size: 12px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
			font-size: 10px;
      white-space: normal;
      text-align: start;
      justify-content: start;
      text-indent: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      line-height: 1.2;
      text-align: left;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      `,
    )}
    @media screen and (min-width: 460px) and (max-width: 660px) {
    font-size: 12px;
    }
`;

export const StyledButton = styled(Button)`
  background: rgba(0, 14, 42, 0.78);
  color: white;
  border: none;
  font-size: 12px; 
  font-family: 'Suit medium';
  cursor: pointer;
  transition: 0.1s ease all;
  &:hover {
    background: rgba(0, 14, 42, 0.78);
    color: white !important;
  }
`

export const Status = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 auto;
`;

export const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`
export const StyledPagination = styled(Pagination)`
  color: rgba(255, 255, 255, 0.5) !important;
  .ant-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    color: rgba(255, 255, 255, 0.5) !important;
    ${({ theme }) =>
        theme.media.monitor(
        `
          font-size: 20px;
        `,
      )}
  }
  svg {
    color: #ffffff !important;

  }
  .ant-pagination-item-active{
    border-color: transparent !important;
  background-color: transparent !important;
  }
  .ant-pagination-item-active a{
    color: #ffffff !important;
    font-weight: 700;
    ${({ theme }) =>
        theme.media.monitor(
        `
          font-size: 20px;
        `,
      )}
  }
`;
export const ScrollButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;