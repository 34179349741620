import styled from "styled-components";
import { Button, Input, List, Select } from "antd";
import { Pagination } from 'antd';
import ProgressBar from "@ramonak/react-progress-bar";

export const ListCss: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  overflow: 'auto',
};

export const MonitoringPageContainer = styled.main`
  width: 100%;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 50px;
      `,
    )}
  ${({ theme }) =>
      theme.media.mobile(
      `
        padding: 15px;
      `,
    )}
`;

export const StyledSearch = styled.div`
  width: 40%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 20px;
  margin-bottom: 45px;
  ${({ theme }) =>
      theme.media.monitor(
      ` 
        width: 60%;
      `,
    )}
    ${({ theme }) =>
      theme.media.tablet(
      ` 
        width: 65%;
      `,
    )}
    ${({ theme }) =>
      theme.media.mobile(
      ` 
        width: 100%;
      `,
    )}
`

export const StyledSelect = styled(Select)`
  font-size: 14px;
  font-family: 'Suit regular';
  font-weight: 500;
  width: 100%;
	height: 35px;
  color: white;
  .ant-select-dropdown {
		background-color: #B2B2B2 !important;
		}
  .ant-select-selector {
    width: 100% !important;
    height: 35px;
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid #B1AEB5 !important;
    border-radius: 0px !important; 
		color: white;
		${({ theme }) =>
    theme.media.monitor(
      `
			height: 52px !important;
			font-size: 18px !important;
      `,
    )}
      ${({ theme }) =>
      theme.media.tablet(
      `
        width:100% !important;
      `,
      )}
    ${({ theme }) =>
      theme.media.mobile(
      `
        width: 100% !important;
      `,
      )}
  }
  .ant-select-clear{
    background-color: transparent !important; 
  }
  .ant-select-selection-item {
    color: white;
  }
  .ant-select-selection-placeholder, .ant-select-selection-search-input{
    display: flex !important;
    font-size: 14px !important;
    color: #BFBFBF !important;
    align-items: center !important;
		${({ theme }) =>
    theme.media.monitor(
      `
				font-size: 18px !important;
      `,
    )}
  }
  &:hover {
    border: none !important;
    border-bottom: 1px solid #B1AEB5 !important; 
    border-color: #B2B2B2;
  }
  &:focus {
    outline: none !important;
  }
	span {
		color: white;
	}
	${({ theme }) =>
    theme.media.monitor(
      `
			height: 52px !important;
			font-size: 18px !important;
      `,
    )}
`;


export const StyledInput = styled(Input)`
  width: 100%;
  border: none !important;
  border-bottom: 1px solid #B1AEB5 !important; 
  border-radius: 0px !important;
  background-color: transparent !important;
  background: transparent !important;
  box-shadow: none !important;
  color: rgba(255, 255, 255, 0.78) !important;
  height: 35px;
  input {
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.78) !important;
    font-size: 14px;
    font-size: 14px;
    font-family: 'Suit regular';
    font-weight: 500;
  }
  input::placeholder {
    font-family: 'Suit regular', 'Inter regular' !important;
    color: rgba(255, 255, 255, 0.78) !important;
  }
  svg { 
    width: 22px;
    height: 22px;
    color: #C7C7C7;
    border: none;
  }
  ${({ theme }) =>
      theme.media.monitor(
      `
      height: 52px;
      font-size: 18px;
      `,
    )}
`;

export const StyledListContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
  ${({ theme }) =>
      theme.media.mobile(
      `
        white-space: nowrap;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: block !important;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(204, 201, 212, 0.2);
          border-radius: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background: #1f1f1f;
        }
      `,
    )}
`;

export const StyledList = styled(List)`
  row-gap: 15px;
  .ant-list{
    background-color: transparent !important;
  }
  .ant-list-header {
    padding-block: 0px;
  }
  .ant-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-spin-nested-loading{
    overflow: auto;
    height: 100%;
  }
  .ant-spin-nested-loading::-webkit-scrollbar{
    display: none;
  }
  .ant-list-empty-text{
    height: 350px;
  }
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 25px;
      `)}
  ${({ theme }) =>
      theme.media.mobile(
      `
        margin-top: 0px;
        padding: 0px !important;
        width: 680px !important;
      `,
  )}
`;

export const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  label {
    font-family: 'Suit medium', 'Inter medium';
    font-size: 12px;
    color: rgba(255, 255, 255, 0.78);
  }
`;

export const Lists = styled.div`
  display: grid;
  grid-template-columns: 90% 5% 5%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SectionHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    color: gray;
    font-size: 13px;
  }
  margin-bottom: 40px;
`;

export const SectionTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
`;

export const TestFields = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 8% 5% 10% 9% 10% 9% 9% 9% 9% 13%;
  grid-column-gap: 1%;
  margin: 0 auto;
  margin-bottom: 5px;
  label {
    font-family: 'Suit bold', 'Inter medium';
    font-size: 15px;
    font-weight: 600;
    display: flex;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.78);
    text-indent: 15px;
  }
  ${({ theme }) =>
      theme.media.monitor(
      `
        label {
          font-size: 20px;
        }
  `)}
  ${({ theme }) =>
    theme.media.tablet(
      `
      font-size: 12px;
			grid-template-columns: 8% 5% 10% 9% 10% 9% 9% 9% 9% 13%;
      label {
        font-family: 'Suit medium', 'Inter medium';
        font-size: 11.5px;
        align-items: start;
        text-indent: 0px;
        font-weight: 400;
      }
      `,
    )}
      ${({ theme }) =>
    theme.media.mobile(
      `
      text-indent: 0px;
			grid-template-columns: 8% 5% 10% 9% 10% 9% 9% 9% 9% 13%;
      label {
        font-family: 'Suit medium', 'Inter medium';
        font-size: 12px;
        align-items: start;
        text-indent: 0px;
        font-weight: 400;
      }
      `,
    )}
`;

export const TestLog = styled.li<{ isVisited: boolean }>`
  background: transparent;
  margin-bottom: 15px;
  cursor: pointer;
  width: 100%;
  height: 45px;
  display: grid;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  grid-template-columns: 8% 5% 10% 9% 10% 9% 9% 9% 9% 13%;
  grid-column-gap: 1%;
  transition: 0.1s ease all;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.78);
  text-overflow: ellipsis;
  opacity: ${(props) => (props.isVisited ? 0.7 : 1)};
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  ${({ theme }) =>
      theme.media.monitor(
      `
        height: 70px;
        grid-template-columns:8% 5% 10% 9% 10% 9% 9% 9% 9% 13%;
        margin-bottom: 15px;
      `)}
  ${({ theme }) =>
      theme.media.tablet(
      `
        grid-template-columns: 8% 5% 10% 9% 10% 9% 9% 9% 9% 13%;
      `,)}
    ${({ theme }) =>
      theme.media.mobile(
      `
        height: 58px;
			  grid-template-columns: 8% 5% 10% 9% 10% 9% 9% 9% 9% 13%;
      `,
      )}
`;

export const TestItem = styled.p`

  text-indent: 14px;
  font-family: 'Suit medium', 'Inter medium';
  color: rgba(255, 255, 255, 0.78);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  overflow: hidden;
  word-break: keep-all;
  font-size: 15px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 25px;
      `)}
  ${({ theme }) =>
    theme.media.tablet(
      `
      font-size: 11.5px;
      align-items: start;
      text-indent: 0px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
      font-size: 11.5px;
      align-items: start;
      text-indent: 0px;
      `,
    )}
    @media screen and (min-width: 420px) and (max-width: 660px) {
    font-size: 12px;
    }
`; 
export const StyledProgress = styled(ProgressBar)`
  width: 90%;
  font-size: 12px;
  span {
     width: 100% !important;
  }
  ${({ theme }) =>
      theme.media.mobile(
      `
        span {
          width: 100% !important;
          padding: 0px !important;
          position: relative !important;
          left: 5px !important;
        }
      `,
    )}
`

export const StyledButton = styled(Button)`
  margin-left: -10px;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 12px; 
  font-family: 'Suit medium';
  cursor: pointer;
  transition: 0.1s ease all;
  &:not(:disabled):hover {
    background-color: transparent !important;
    background: transparent !important;
    color: white !important;
  }
  svg { 
    width: 25px;
    height: 25px;
    color: #C7C7C7;
    border: none;
  }
`

export const Status = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-left: 15px;
  ${({ theme }) =>
      theme.media.tablet(
      `
        width: 15px;
        height: 15px;
      `,
    )}
  ${({ theme }) =>
      theme.media.mobile(
      `
        width: 15px;
        height: 15px;
      `,
    )}
`;

export const StyledStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`

export const StyledPagination = styled(Pagination)`
color: rgba(255, 255, 255, 0.5) !important;
.ant-pagination{
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  color: rgba(255, 255, 255, 0.5) !important;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
}
svg {
  color: #ffffff !important;

}
.ant-pagination-item-active{
  border-color: transparent !important;
  background-color: transparent !important;
}
.ant-pagination-item-active a{
  color: #ffffff !important;
  font-weight: 700;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
}
.ant-select-selector {
  background: transparent !important;
  border: none !important;
}
.ant-select-selection-item{
  color: rgba(255, 255, 255, 0.6) !important;
}
`;

export const ScrollButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 100px;
      `,
    )}
`;
export const ButtonStyle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  width: 5%;
  height: 100%;
`;

export const ScrollButton = styled.button``;