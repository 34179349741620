import styled from "styled-components";
import { Form, Input } from "antd";

export const StyledForm = styled(Form)`
	margin-top: 20px;
  .ant-form-item-label > label{
    width: 120px;
    font-size: 15px;
    text-align: left;
    font-family: 'Suit bold','Inter medium';
    font-weight: 500;
    color: rgba(0, 0, 0, 0.56) !important;
    ${({ theme }) =>
      theme.media.mobile(
      `
        font-size: 14px;
        width: 90px;
      `,
      )}
  }
`

export const StyeldItem = styled(Form.Item)`

`

export const StyledInput = styled(Input)`
	background: rgba(150, 150, 150, 0.4);
	border-radius: 5px;
	border: none;
	:hover { 
		background: rgba(150, 150, 150, 0.6);
	}
`