import { wefloLocalStorage } from '@/configs';
import { authorization } from '@/constants';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

export function useSignOut() {
  const navigate = useNavigate();

  const signOut = () => {
    wefloLocalStorage.removeItem(authorization);
    message.success('로그아웃 되었습니다.');
    navigate('/signin');
  };

  return { signOut };
}