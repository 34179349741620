import styled from 'styled-components';
import { Modal, ModalProps } from 'antd';

const StyledModal = styled(Modal)`
  width: 980px !important;
  ${({ theme }) =>
      theme.media.monitor(
      `
        width: 60% !important;
      `,
    )}
    ${({ theme }) =>
      theme.media.tablet(
      `
        width: 740px !important;
      `,
    )}
    ${({ theme }) =>
      theme.media.mobile(
      `
        width: 95% !important;
        top: 30px !important;
      `,
    )}
  .ant-modal {
    width: 980px !important;
    ${({ theme }) =>
      theme.media.monitor(
      `
        width: 60% !important;
      `,
    )}
    ${({ theme }) =>
      theme.media.tablet(
      `
        width: 70% !important;
      `,
    )}
    ${({ theme }) =>
      theme.media.mobile(
      `
        width: 95% !important;
      `,
    )}
  }
  .ant-modal-title {
    font-family: 'SUIT extra bold';
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
    margin-top: 20px;
    letter-spacing: 1px;
    margin-bottom: 40px;
    ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 30px;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 40px;
      `,
    )}
    ${({ theme }) =>
      theme.media.tablet(
      `
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 20px;
      `,
    )}
    ${({ theme }) =>
      theme.media.mobile(
      `
        font-size: 18px;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 10px;
      `,
    )}
  } 
  .ant-modal-content {
    box-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38);
    background:#1b1b1b !important;
    border: 1px solid #ffffff4a;
    margin: 0 auto !important;
    overflow: auto;
    padding: 20px 32px !important;
    ${({ theme }) =>
      theme.media.mobile(
      `
        padding: 5px !important;
      `,
    )}
  }
  .ant-modal-header{
    background: transparent;
  }
  .ant-modal-body{
    width: 100% !important;
  }

  button {
    color: rgba(255, 255, 255, 0.87) !important;
  }
`

export function MainModal({ ...restProps }: ModalProps) {
  return <StyledModal {...restProps} maskStyle={{background: ' rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(4px)'}}/>;
}
