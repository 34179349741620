import { FormInstance } from "antd";
import { useEffect, useState, memo, useCallback } from "react";
import { useGetVehicleDiagSummary } from "@/features/data";
import { VehicleDiagSummaryType } from "@/features/data";
import { useNavigate } from "react-router-dom";
import { LineChart } from "../BarChart";
import { ContentSubTitle, ContentText, ContentTitle, GridListItem, GridRowListItem, GridSearchList, StyledForm, StyledGrid, StyledVehicleInOut, SubTitle, ScrollButtonContainer, InspectionText,
  StyledPagination, 
  ListContainer } from "./style";
import { StyleResult, Car, GridCar, StyledResultCar, CarList, StyleResultText, InspectionColor } from "../InspectionResult/styles";
import { useDamageArea } from '@/features/cardata';
import { Summary, VehicleInOutType } from "../VehicleImage/types";

type VehicleHistoryProps = {
  form: FormInstance;
  inspec_id: number;
  InspectionTitle: number[];
  combinedSummaryFront: Summary;
  combinedSummaryBack: Summary;
  InspectionColorPoint: string[];
  vehicle_id: number;
  carnum: String;
  vehicleInOutData: VehicleInOutType;
  totalDamage: number;
  beforeTotalDamage: number;
};

function VehicleHistory({
  form,
  inspec_id,
  InspectionColorPoint,
  InspectionTitle,
  combinedSummaryBack,
  combinedSummaryFront,
  vehicle_id,
  carnum,
  vehicleInOutData,
  totalDamage,
  beforeTotalDamage
}: VehicleHistoryProps) {
  const { data } = useGetVehicleDiagSummary(vehicle_id, 1, 1000, true);
  const [paginationPage, setPaginationPage] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(data?.items?.length || 0);
  const [webSize, setWebSize] = useState('notebook');
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.meta && !dataLoaded) {
      setDataLoaded(true);
    }
  }, [data, dataLoaded]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setWebSize('mobile');
      } else if (window.innerWidth < 1540) {
        setWebSize('notebook');
      } else {
        setWebSize('desktop');
      }
    };
    
    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onClick = useCallback((item: VehicleDiagSummaryType) => {
    navigate(`/search/${carnum}/${item.inspection_id}`, {
      state: {
        vrn: carnum,
        num: item.inspection_id,
        list: null,
      }
    });
  }, [navigate, carnum]);

  const handleUpLimit = useCallback(() => { 
    const newLimit = Math.floor(endIndex * 0.9); 
    if(startIndex > 0 && endIndex-startIndex === 1 ) {
      setStartIndex((prev) => prev - 1);
      setEndIndex((prev) => prev - 1);
    }
    else if(startIndex > 0 && endIndex > 1) {
      setStartIndex((prev) => prev - 1);
      setEndIndex((prev) => prev - 2);
    }else {
      setEndIndex(newLimit > 1  ? newLimit : 1);
    }
  }, [data, startIndex, endIndex]);
  
  const handleDownLimit = useCallback(() => {
    const totalItems = data?.meta.totalItems;
    const newLimit = Math.ceil(endIndex * 1.1); 
    if(!totalItems) return;
    if(startIndex > 0 && endIndex === data?.items?.length) {
      setStartIndex((prev) => prev - 1);
    }else if(startIndex > 0 && endIndex < data?.items?.length) {
      setStartIndex((prev) => prev - 1);
      setEndIndex((prev) => prev + 1);
    }else{
      setEndIndex(newLimit < totalItems ? newLimit : totalItems);
    }
  }, [data, startIndex, endIndex]);

  const handleSlideRight = useCallback(() => {
    if (!data?.items || startIndex + (endIndex - startIndex) >= data?.meta.totalItems || endIndex === data?.items?.length) return;
    setStartIndex((prev) => prev + 1);
    setEndIndex((prev) => prev + 1);
  }, [data, startIndex, endIndex]);
  
  const handleSlideLeft = useCallback(() => {
    if (!data?.items || startIndex < 1) return;
    setStartIndex((prev) => prev - 1);
    setEndIndex((prev) => prev - 1);
  }, [data, startIndex, endIndex]);

  const itemsWithIndex = data?.items?.map((item, idx) => ({
    ...item,
    index: data?.meta?.totalItems - ((data?.meta?.currentPage - 1) * data?.meta?.itemsPerPage + idx),
  })).sort((a, b) => a.index - b.index);
  
  const filterData = itemsWithIndex?.slice(startIndex, endIndex);

  const size = webSize === 'desktop' ? 14 : webSize === 'notebook' ? 12 : 10;

  const paginatedData = data?.items?.slice((paginationPage- 1) * 4, paginationPage * 4);

  return (
    <StyledForm form={form} requiredMark={false} layout="vertical">
      <StyledGrid>
        <StyleResult style={{ width: '100%', border: 'none', justifyContent: 'space-between' }}>
          <SubTitle>차량 상태 분석</SubTitle>
          <StyledResultCar style={{ width: '80%', marginTop: 10, marginBottom: 10 }}>
            <CarList>
              <Car src='/assets/images/front_car.png' />
              <GridCar>
                {useDamageArea(combinedSummaryFront, 'front')}
              </GridCar>
            </CarList>
            <CarList>
              <Car src='/assets/images/back_car.png' />
              <GridCar>
                {useDamageArea(combinedSummaryBack, 'back')}
              </GridCar>
            </CarList>
          </StyledResultCar>
          <StyleResultText style={{ marginBottom: 0 }}>
            <InspectionColor style={{ background: InspectionColorPoint[0], width: size, height: size }} />
            <InspectionText style={{ fontSize: webSize }}>Scratch: {InspectionTitle[0]}</InspectionText>
            <InspectionColor style={{ background: InspectionColorPoint[1], width: size, height: size }} />
            <InspectionText style={{ fontSize: webSize }}>Separated: {InspectionTitle[1]}</InspectionText>
            <InspectionColor style={{ background: InspectionColorPoint[2], width: size, height: size }} />
            <InspectionText style={{ fontSize: webSize }}>Crushed: {InspectionTitle[2]}</InspectionText>
            <InspectionColor style={{ background: InspectionColorPoint[3], width: size, height: size }} />
            <InspectionText style={{ fontSize: webSize }}>Breakage: {InspectionTitle[3]}</InspectionText>
          </StyleResultText>
        </StyleResult>
        <StyleResult style={{ width: '100%', border: 'none', justifyContent: 'space-between' }}>
          <SubTitle>최근 출입차 정보</SubTitle>
          <ContentSubTitle>차량의 최근 출입차 정보를 출력합니다.</ContentSubTitle>
          <StyledGrid style={{height: '100%'}}>
            {vehicleInOutData?.before.direction ?
              <StyledVehicleInOut>
                <ContentTitle style={{ fontWeight: 700, justifyContent: 'center', marginTop: 5, marginBottom: 5 }}>{vehicleInOutData?.before.direction === "backward" ? "출차" : vehicleInOutData?.before.direction === "forward" ? "입차" : ""}</ContentTitle>
                <ContentTitle>{vehicleInOutData?.before?.station_name}</ContentTitle>
                <ContentText>{vehicleInOutData?.before?.address}</ContentText>
                <ContentText>{`${vehicleInOutData?.before?.createdAt?.split('T')[0]} (${vehicleInOutData?.before?.createdAt?.split('T')[1].split('.')[0]})`}</ContentText>
                <ContentText>파손 수 : {beforeTotalDamage}</ContentText>
              </StyledVehicleInOut> :
              <StyledVehicleInOut style={{ justifyContent: 'start' }}>
                <ContentTitle style={{ fontWeight: 700, justifyContent: 'center', marginTop: 5, marginBottom: 5 }}>{vehicleInOutData?.after.direction === 'backward' ? "입차" : "출차"}</ContentTitle>
                <ContentText style={{justifyContent: 'center', marginLeft: 0, marginTop: 20}}>{vehicleInOutData?.after.direction === 'backward' ? "입차" : "출차"} 데이터가 존재하지 않습니다.</ContentText>
              </StyledVehicleInOut>
            }
            {vehicleInOutData?.after.direction ?
              <StyledVehicleInOut>
                <ContentTitle style={{ fontWeight: 700, justifyContent: 'center', marginTop: 5, marginBottom: 5 }}>{vehicleInOutData?.after.direction === "backward" ? "출차" : vehicleInOutData?.after.direction === "forward" ? "입차" : ""}</ContentTitle>
                <ContentTitle>{vehicleInOutData?.after?.station_name}</ContentTitle>
                <ContentText>{vehicleInOutData?.after?.address}</ContentText>
                <ContentText>{`${vehicleInOutData?.after?.createdAt?.split('T')[0]} (${vehicleInOutData?.after?.createdAt?.split('T')[1].split('.')[0]})`}</ContentText>
                <ContentText>파손 수 : {totalDamage}</ContentText>
              </StyledVehicleInOut> :
              <StyledVehicleInOut style={{ justifyContent: 'start' }}>
                <ContentTitle style={{ fontWeight: 700, justifyContent: 'center', marginTop: 5, marginBottom: 5 }}>출차</ContentTitle>
                <ContentText>after 데이터가 존재하지 않습니다.</ContentText>
              </StyledVehicleInOut>
            }
          </StyledGrid>
        </StyleResult>
      </StyledGrid>
      <div>
        <SubTitle>진단 히스토리</SubTitle>
        <LineChart data={filterData} inspec_id={inspec_id} handleUpLimit={handleUpLimit} handleDownLimit={handleDownLimit} handleSlideLeft={handleSlideLeft} handleSlideRight={handleSlideRight}/>
      </div>
      <GridSearchList>
        <SubTitle>상세 조회</SubTitle>
        <GridRowListItem>
          <div>{'진단일시'}</div>
          <div>{'공업사'}</div>
          <div>{'출입차'}</div>
          <div>{`총 파손 수`}</div>
          <div>{`상세 조회`}</div>
        </GridRowListItem>
        <ListContainer>
          {paginatedData?.map((item) => {
            if (Object.keys(item).length !== 0)
              return (
                <GridListItem
                  key={item.inspection_id}
                  onClick={() => onClick(item)}
                  style={{ cursor: 'pointer', backgroundColor: item.inspection_id === inspec_id ? '#5b5b5b' : 'transparent' }}
                >
                  <div>{item.createdAt}</div>
                  <div>{item.station_name}</div>
                  <div>{item.direction === "backward" ? "출차" : "입차"}</div>
                  <div>{`${item.sum_bbox_num || '0'} 건`}</div>
                  <div>{`Scr: ${item.sum_scratch_num || '0'} Sep: ${item.sum_separated_num || '0'} Crsh: ${item.sum_crushed_num || '0'} Brkg: ${item.sum_breakage_num || '0'}`}</div>
                </GridListItem>
              )
          })}
        </ListContainer>
        <ScrollButtonContainer>
          <StyledPagination current={paginationPage} total={data?.meta?.totalItems} pageSize={4} onChange={(value: any) => { setPaginationPage(value)}}/>
        </ScrollButtonContainer>
      </GridSearchList>
    </StyledForm>
  );
}

export default memo(VehicleHistory);