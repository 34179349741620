import styled from 'styled-components';

export const TitleContainer = styled.div`
  font-size: 26px;
  font-weight: 800;
  text-align: center;
  line-height: 45px;
  color: #000000;
  font-family: 'Digital Sans Now Ml Pro bold', 'Suit bold';
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  ${({ theme }) =>
    theme.media.mobile(
      `
       font-size: 19.5px;
      `,
    )}
`;
