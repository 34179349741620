import styled from 'styled-components';

export const SignInWrapper = styled.main`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-image: url('assets/images/backgroundImg.png');
  background-size: cover;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 50px;
      `,
    )}
`;

export const IntroGroup = styled.div`
  margin-top: 55px;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  row-gap: 40px;
`;

export const IntroLogo = styled.img`
  width: 260px;
  height: auto;
  ${({ theme }) =>
    theme.media.mobile(
      `
       width: 190px;
      `,
    )}
`;

export const IntroTextGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 8px;
`

export const IntroText = styled.div`
  font-family: 'Digital Sans Now Ml Pro bold';
  font-weight: 700;
  font-size: 32px;
  color: #FFFFFF;
  font-style: normal;
  line-height: 48px;
  ${({ theme }) =>
    theme.media.mobile(
      `
       font-size: 21px;
      `,
    )}
`;

export const IntroSubText = styled.div`
  font-family: 'Digital Sans Now Ml Pro regular';
  font-size: 23px;
  font-weight: 400;
  color: #FFFFFF;
  ${({ theme }) =>
    theme.media.monitor(
      `
       font-size: 32px;
      `,
    )}
`;