import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const StyledLayout = styled(Layout)`
  width: 100%;
  background-color: #000000;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

export const StyledContent = styled(Content)`
  width: 100%;
  margin: 0 auto;
  margin-top: 58px;
  padding: 40px;
  min-height: calc(100vh - 58px);
  ${({ theme }) =>
    theme.media.monitor(
      `
      padding: 60px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
      padding: 5px !important;
      `,
    )}
`;