import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import "../fonts/font.css"

export const GlobalStyle = createGlobalStyle`
${reset}
  * {
    padding: 0;
    margin: 0;
    line-height: 100%;
    list-style: none;
    font-family: 'Suit medium', 'Inter medium';
  }
  html {
    font-size: 14px;
  }
  body{
    line-height: 22px;
    background-color: #000000;
    padding: 0;
    margin: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  input, button {
    background-color: transparent;
    border: none;
    outline: none;
  }
  div, span {
    font-family: 'Suit medium';
  }
  h1, h2, h3, h4, h5, h6{
    font-family:'Suit bold', sans-serif;
  }
  ol, ul, li {
    list-style: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :where(.ant-image).ant-image {
    @media only screen and (max-width: 1025px) {
      width: 100%;
    }
  }
`;
