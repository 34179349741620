import styled from "styled-components";

export const StyledGalleryItem = styled.div`
	width: 100%;
	height: 220px;
	box-sizing: border-box;
	background: rgba(0, 0, 0, 0.39);
	border: 2px solid #FFFFFF;
	border-radius: 5px;
	position: relative;
	${({ theme }) =>
    theme.media.mobile(
      `	
				background: rgba(255, 255, 255, 0.39);
				width: 100%;
     		height: 106px;
      `,
    )}
`;

export const StyledImgContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
`;

export const StyledImg = styled.img`
	width: 50%;
	height: 100%;
	object-fit: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	${({ theme }) =>
    theme.media.mobile(
      `	
				width: 100%;
     		height: 100%;
      `,
    )}
`;

export const CameraBtn = styled.label`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		background-color: rgba(0, 0, 0, 0.83);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}
`;

export const InputGroup = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	z-index: 100;
`;

export const Input = styled.input`
		visibility: hidden;
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
`;