import styled from "styled-components";

export const MainPageContainer = styled.main`
  width: 100%;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 50px;
      `,
    )}
`;

export const TitleCompany = styled.div`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  color: #53B5F9;
  font-family: 'Digital Sans Now Ml Pro bold', 'Suit bold';
  margin: 0 auto;
  margin-top: 10vw;
  ${({ theme }) =>
    theme.media.monitor(
      `
       font-size: 36px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
       font-size: 18px;
      `,
    )}
`;

export const Context = styled.div`
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #000000;
    font-family: 'Suit bold', 'Digital Sans Now Ml Pro bold';
    margin: 0 auto;
    margin-top: 2vw;
    ${({ theme }) =>
      theme.media.monitor(
        `
        font-size: 28px;
        `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
       font-size: 13.3px;
      `,
    )}
`
export const SearchForm = styled.div`
  display: flex;
  margin-top: 3vw;
  column-gap: 1vw;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 5vw;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
      margin-top: 26vw;
      column-gap: 3vw;
      padding: 12px;
      `,
    )}
`

export const StyledInput = styled.input`
  width: 700px;
  height: 75px;
  background: #F3F4F5;
  border: 1px solid;
  border-color: #e3e3e3;
  border-radius: 8px;
  font-size: 23px;
  text-indent: 40px;
  color: #252525;
  font-family: 'Suit semi bold';
  font-weight: 400;
  &::placeholder {
    font-size: 20px;
    font-weight: 400;
    color: #A2A2A2;
    font-family: 'Suit medium';
    text-indent: 40px;
  }
  ${({ theme }) =>
    theme.media.monitor(
      `
        width: 1000px;
        height: 110px;
        font-size: 28px;
        &::placeholder {
          font-size: 28px;
        }
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
      width: 100%;
      height: 65px;
      font-size: 19px;
      &::placeholder {
        font-size: 17px;
      }
      `,
    )}
`
export const StyledButton = styled.button`
  width: 75px;
  height: 75px;
  background: #5A27DB;
  border-radius: 8px;
  font-size: 20px;
  color: #ffffff;
  font-family: 'Suit semi bold';
  ${({ theme }) =>
    theme.media.monitor(
      `
       height: 110px;
       width: 100px;
       font-size: 28px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
      width: 65px;
      height: 65px;
      font-size: 16px;
      `,
    )}
`
