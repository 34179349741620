import styled from 'styled-components';
import { Button } from 'antd';

export const NotFoundContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  background-image: url("assets/images/backgroundImg.png");
  background-size: cover;
  h2 {
    color: white;
  }
  span {
    color: white;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #2c2c32;
  &:hover {
    border: 1px solid #2c2c32 !important;
    color: black !important;
  }
`;
