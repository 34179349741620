import { DamageCar } from "../components/InspectionResult/styles";
import { Summary } from "../components/VehicleImage/types";

export function useDamageArea(summary: Summary, type: string) {
	const damageAreaComponents = [];
	if(summary) {
		if(type === 'front'){ 
			if(summary?.a_pillar_l) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/a_pillar_l_${summary?.a_pillar_l}.png`}/>);
			if(summary?.b_pillar_l)damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/b_pillar_l_${summary?.b_pillar_l}.png`}/>);
			if(summary?.c_pillar_l) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/c_pillar_l_${summary?.c_pillar_l}.png`}/>);
			if(summary?.front_bumper) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/front_bumper_${summary?.front_bumper}.png`}/>);
			if(summary?.front_fender_l)damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/front_fender_l_${summary?.front_fender_l}.png`}/>);
			if(summary?.front_wheel_l) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/front_wheel_l_${summary?.front_wheel_l}.png`}/>);
			if(summary?.rear_wheel_l)damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/rear_wheel_l_${summary?.rear_wheel_l}.png`}/>);
			if(summary?.door_l) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/door_l_${summary?.door_l}.png`}/>);
			if(summary?.side_mirror_l) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/side_mirror_l_${summary?.side_mirror_l}.png`}/>);
			if(summary?.bonnet) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/bonnet_${summary?.bonnet}.png`}/>);
			if(summary?.head_lights_l) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/head_lights_l_${summary?.head_lights_l}.png`}/>);
			if(summary?.head_lights_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/head_lights_r_${summary?.head_lights_r}.png`}/>);
			if(summary?.rocker_panel_l) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/rocker_panel_l_${summary?.rocker_panel_l}.png`}/>);
			if(summary?.rear_fender_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/rear_fender_r_${summary?.rear_fender_r}.png`}/>);
			if(summary?.windshield) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/windshield_${summary?.windshield}.png`}/>); 
			if(summary?.rear_bumper) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/rear_bumper_${summary?.rear_bumper}.png`}/>); 
			if(summary?.roof) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/roof_${summary?.roof}.png`}/>); 
			if(summary?.side_mirror_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/front/side_mirror_r_${summary?.side_mirror_r}.png`}/>);
		}else{
			if(summary?.a_pillar_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/a_pillar_r_${summary?.a_pillar_r}.png`}/>);
			if(summary?.b_pillar_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/b_pillar_r_${summary?.b_pillar_r}.png`}/>);
			if(summary?.c_pillar_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/c_pillar_r_${summary?.c_pillar_r}.png`}/>);
			if(summary?.rear_bumper) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/rear_bumper_${summary?.rear_bumper}.png`}/>);
			if(summary?.front_fender_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/front_fender_r_${summary?.front_fender_r}.png`}/>);
			if(summary?.rear_fender_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/rear_fender_r_${summary?.rear_fender_r}.png`}/>);
			if(summary?.front_wheel_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/front_wheel_r_${summary?.front_wheel_r}.png`}/>);
			if(summary?.rear_wheel_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/rear_wheel_r_${summary?.rear_wheel_r}.png`}/>);
			if(summary?.rear_lamp_l) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/rear_lamp_l_${summary?.rear_lamp_l}.png`}/>);
			if(summary?.rear_lamp_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/rear_lamp_r_${summary?.rear_lamp_r}.png`}/>);
			if(summary?.door_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/door_r_${summary?.door_r}.png`}/>);
			if(summary?.trunk_lid) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/trunk_lid_${summary?.trunk_lid}.png`}/>);
			if(summary?.rocker_panel_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/rocker_panel_r_${summary?.rocker_panel_r}.png`}/>);
			if(summary?.side_mirror_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/side_mirror_r_${summary?.side_mirror_r}.png`}/>);
			if(summary?.roof) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/roof_${summary?.roof}.png`}/>);
			if(summary?.rear_windshield) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/rear_windshield_${summary?.rear_windshield}.png`}/>);
			if(summary?.bonnet) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/bonnet_${summary?.bonnet}.png`}/>);
			if(summary?.front_bumper) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/front_bumper_${summary?.front_bumper}.png`}/>);
			if(summary?.head_lights_r) damageAreaComponents.push(<DamageCar src={`/assets/damaged_area/back/head_lights_r_${summary?.head_lights_r}.png`}/>);
		}
	}
  return damageAreaComponents;
}
