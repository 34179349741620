import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, type MenuProps } from 'antd';
import { FaUser } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faBars, faXmark, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useSignOut, useAuthInfo } from '@/features';
import { 
    StyledHeader, MenuBlock, LogoContainer, Logo, StyledMenu, UserInfo, MobileStyle, ToggleBtn, StyledToggleContainer, StyledMenuData, StyledToggleMenu, LogoutBtn } from './styles';

export function Header() {
  const location = useLocation();
  const navigate = useNavigate(); 
  const { authInfo } = useAuthInfo();
  const { signOut } = useSignOut();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState(location.pathname.split('/')?.[1] || '');     
  const { username } = authInfo || {};

  const handleLogoClick = () => {                                                                        
    navigate(username ? '/search' : '/');
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {                              
    setCurrentPath(e.key);
    navigate(`/${e.key}`);
  };

  const handleSignOut = () => {                                                               
    signOut();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems: MenuProps['items'] = [                                                                     
    { label: '차량번호 조회', key: 'search' },
    { label: '외관 사진 리스트', key: 'carlist' },
    username === 'weflo' ? { label: '시스템 모니터링', key: 'monitoring' } : null,
    {label: '사진 촬영', key: 'photoshoot' },
  ];

  const dropdownItems: MenuProps['items'] = [                                           
    {
      label: '로그아웃',
      key: 'signOut',
      onClick: handleSignOut,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {!isMobile ? (
        <StyledHeader>
          <MenuBlock>
            <LogoContainer>
              <Logo src="/assets/logos/logo.png" onClick={handleLogoClick} />
            </LogoContainer>
            <StyledMenu
              selectedKeys={[currentPath]}
              mode="horizontal"
              items={menuItems}
              onClick={handleMenuClick}
            />
          </MenuBlock>
          {username && (
            <Dropdown menu={{ items: dropdownItems }}>
              <UserInfo>
                <FaUser />
                {username}
              </UserInfo>
            </Dropdown>
          )}
        </StyledHeader>
      ) : (
        <MobileStyle>
          <ToggleBtn style={{ left: 32 }}>
            <Logo src="/assets/logos/logo.png" onClick={handleLogoClick} />
          </ToggleBtn>
          <ToggleBtn onClick={toggleMenu}>
            <FontAwesomeIcon icon={isMenuOpen ? faXmark : faBars} fontSize={24} color='white' />
          </ToggleBtn>
          <StyledToggleContainer>
            {isMenuOpen && (
              <StyledMenuData>
                <StyledToggleMenu
                  selectedKeys={[currentPath]}
                  mode="inline"
                  items={menuItems}
                  onClick={handleMenuClick}
                />
                <LogoutBtn onClick={handleSignOut}>
                  <FontAwesomeIcon icon={faSignOut} color='white' fontSize={20} />
                  <p>로그아웃</p>
                </LogoutBtn>
              </StyledMenuData>
            )}
          </StyledToggleContainer>
        </MobileStyle>
      )}
    </>
  );
}