import styled from "styled-components";
import { Image } from "antd";
import Icon from "@ant-design/icons/lib/components/Icon";

export const Container = styled.div`
	width: 100%;
	height: 100%;
`;

export const ImageStyle = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	flex-direction: column;
	border-radius: 5px;
	position: relative;
	padding: 5px;
`;

export const LoadingDiv = styled.div`
	display: flex;
	width: 100%;
	height: 10vw;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		align-items: center;
	}
`;

export const IconStyle = styled(Icon)`
	position: absolute;
	z-index: 100;
	top: 8px;
	right: 8px;
	font-size: 20px;
	img {
		width: 30px !important;
		height: 30px;
	}
`

export const Images = styled(Image)`
	width: 100% !important;
	height: 250px !important; 
	object-fit: cover;
	${({ theme }) =>
    theme.media.tablet(
      `
				height: 180px !important;
      `,
    )}
		${({ theme }) =>
    theme.media.mobile(
      `
				height: 120px !important;
			`,
		)}
`;