import { wefloLocalStorage } from "@/configs";
import { authorization } from "@/constants";
import { message } from "antd";
import { AxiosError } from "axios";

export const handleQueryError = (err: AxiosError) => {
	if (err?.message === 'Network Error') {
		message.error('서버에 연결할 수 없습니다.');
	} else {
		switch (err?.response?.status) {
			case 403: 
				wefloLocalStorage.removeItem(authorization);
				window.location.href = '/signin';
				break;
			default:
				console.log(err?.response?.data);
				message.error(err?.response?.data.data.msg ||'서버에서 에러가 발생했습니다.');
		}
	}
}