import { RecoilRoot } from 'recoil';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { MainRoutes } from './routes';
import { GlobalStyle, ThemeProvider, theme } from '@/styles';
import { queryClient } from './configs';

function App() {
  return (
    <RecoilRoot>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <MainRoutes />
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;