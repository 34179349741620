import styled from 'styled-components';
import { Spin } from 'antd';

export const IntroWrapper = styled.main`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-image: url('assets/images/backgroundImg.png');
  background-size: cover;
`;

export const IntroBlur = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(21px);
  z-index: 100;
`;

export const StyledSpin = styled.div`
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const LoadingSpinIcon = styled(Spin)`  
  .ant-spin-dot-item {
    background-color: white;
    z-index: 1000;
  }
  .ant-spin-text {
    z-index: 1000;
    color: white;
    text-align: center;
    text-shadow: none !important;
    font-family: 'Digital Sans Now Ml Pro regular';
    font-size: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
`;