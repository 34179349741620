import { Input, Select } from "antd";
import styled from "styled-components";

export const StyledLayout = styled.div`
	width: 88%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	margin-bottom: 20px;
`;

export const StyledHeader = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	margin-top: 35px;
	row-gap: 8px;
	margin-bottom: 25px;
	${({ theme }) =>
		theme.media.mobile(
			`	
				margin-bottom: 0px;
			`,
		)}
`

export const StyledHeaderTitle = styled.div`
	font-size: 26px;
	font-family: 'Suit bold';
	color: #ffffff;
	${({ theme }) =>
    theme.media.mobile(
      `	
				font-size: 20px;
      `,
    )}
`;

export const StyledHeaderItem = styled.div`
	font-size: 20px;
	font-family: 'Suit medium';
	color: #AAAAAA;
	${({ theme }) =>
    theme.media.mobile(
      `	
				font-size: 15px;
      `,
    )}
	`;

export const StyledItem = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	column-gap: 20px;
	justify-content: start;
	align-items: start;
	margin-top: 10px;
	margin-bottom: 10px;
	${({ theme }) =>
		theme.media.mobile(
			`	
				margin-bottom: 0px;
			`,
		)}
`;

export const StyledLabel = styled.label`
	font-size: 20px;
	font-family: 'Suit medium';
	color: rgba(255, 255, 255, 0.84);
	width: 10%;
	height: 45px;
	display: flex;
	align-items: center;
	text-align: center;
	${({ theme }) =>
		theme.media.mobile(
			`	
				width: 20%;
				font-size: 16px;
				height: 32px;
			`,
		)}
`;


export const StyledInput = styled(Input)`
  width: 90%;
  font-family: 'Suit medium', 'Inter medium' ;
  color: rgba(255, 255, 255, 0.87);
	font-size: 20px;
  font-weight: 400;
  background: transparent !important;
  border-radius: 0px !important;
  border-color: #B1AEB5 !important;
  border: none;
  border-bottom: 2px solid #B1AEB5 !important;
  box-shadow: none !important;
  ::placeholder {
    color: rgba(255, 255, 255, 0.34) !important;
		font-size: 19px;
  }
	${({ theme }) =>
		theme.media.mobile(
			`	
				width: 80%;
				font-size: 16px;
				::placeholder {
					font-size: 16px;
				}
			`,
		)}
`;


export const StyledSelect = styled(Select)`
	width: 90%;
  font-family: 'Suit medium','Inter medium' ;
  color: rgba(255, 255, 255, 0.87);
	${({ theme }) =>
		theme.media.mobile(
			`	
				width: 80%;
			`,
		)}
  .ant-select-selection-item {
    color: rgba(255, 255, 255, 0.87) !important;
    font-family: 'Suit medium', 'Inter medium' !important;
    font-weight: 400 !important;
		font-size: 20px;
		${({ theme }) =>
		theme.media.mobile(
			`	
				font-size: 16px;
			`,
		)}
  }
  .ant-select-selection-placeholder {
		font-size: 16px;
    font-family: 'Suit medium', 'Inter medium';
    color: rgba(255, 255, 255, 0.34) !important;
    font-size: 19px;
		${({ theme }) =>
		theme.media.mobile(
			`	
				font-size: 15px;
			`,
		)}
  }
  .ant-select-selector {
    border-color: #B1AEB5 !important;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 2px solid #B1AEB5 !important;
    background: transparent !important;
    height: 37px !important;
  }

  &.ant-select.ant-select-error .ant-select-selector,
  &.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: red !important;
  }
	.ant-select-arrow { 
		color: white;
		font-size: 16px;
	}
  :focus {
    outline: none !important;
    border-color: transparent !important;
  }
`;

export const StyledContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	row-gap: 20px;
	margin-bottom: 70px;
	${({ theme }) =>
		theme.media.mobile(
			`	
				margin-bottom: 0px;
			`,
		)}
`;

export const StyledQuestion = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: 75% 20%;
	grid-column-gap: 5%;
`;

export const StyledQuestionItem = styled.div`
	font-size: 15px;
	font-family: 'Suit medium';
	color: rgba(255, 255, 255, 0.84);
	span {
		color: #FF0000;
	}
`;

export const StyledQuestionBtn = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: row;
	column-gap:  15px;
`

export const StyledQuestionBtnItem = styled.button`
	font-size: 16px;
	font-family: 'Inter medium';
	color: rgba(255, 255, 255, 0.84);
	background: transparent;
	border: none;
	border-bottom: 1.5px solid rgba(255, 255, 255, 0.84);
	cursor: pointer;
`;

export const StyledGallery = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 48% 48%;
	grid-column-gap: 4%;
	grid-row-gap: 4%;
	margin-bottom: 30px;
`;

export const StyledButton = styled.button`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	height: 50px;
	background: #5A27DB;
	color: white;
	font-family: 'Suit bold';
	font-size: 18px;
	border-radius: 5px;
`;

export const TitleGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 0px;
  margin: 0 auto;
  margin-top: 30px;
  column-gap: 10px;
`;

export const TitleContent = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Suit medium';
  color: rgba(0, 0, 0, 0.87);
  float: left;
  margin-right: 10px;
  cursor: pointer;
`;