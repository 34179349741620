import styled from 'styled-components';
import { DatePicker, Select, Button, Form } from 'antd';

const { RangePicker } = DatePicker;

export const StyledSearchBarDiv = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 20px;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	margin-top: 25px;
	${({ theme }) =>
    theme.media.mobile(
      `
				margin-top: 10px;
      `,
    )}
`;

export const StyledForm = styled(Form)`
	width: 100%;
	${({ theme }) =>
    theme.media.mobile(
      `
			margin-bottom: 30px;
			.ant-form-item {
				margin-bottom: 10px !important;
			}
      `,
    )}
	
`;

export const StyledSearchBar = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const StyledFormitem = styled(Form.Item)`
	width: 100%;
	margin: 0 auto;
	${({ theme }) =>
    theme.media.tablet(
      `
			width: 45%;
      `,
    )}
	${({ theme }) =>
    theme.media.mobile(
      `
			width: 46%;
			font-size: 12px;
      `,
    )}
`

export const SearchList = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	column-gap: 30px;
	${({ theme }) =>
    theme.media.monitor(
      `
			grid-row-gap: 0px;
			column-gap: 45px;
      `,
    )}
	${({ theme }) =>
    theme.media.tablet(
      `
			grid-column-gap: 1vw;
      `,
    )}
			${({ theme }) =>
    theme.media.mobile(
      `
			width: 100%;
			grid-template-columns: 70% 30%;
			grid-column-gap: 10px;
      `,
    )}
`;

export const StyledRangePicker = styled(RangePicker)`
	font-family: 'Suit regular'!important;
	font-size: 14px;
	background-color: transparent !important;
	background: transparent !important;
	color: white;
	border: none;
	border-bottom: 1px solid #B1AEB5;
	border-radius: 0px;
	:hover { 
		border: none;
		border-bottom: 1px solid #B1AEB5;
	}
	path{
		color: white;
	}
	input::placeholder {
		color:#505050 !important;
	}
`

export const SelectStyled = styled(Select)`
  font-size: 14px;
  font-family: 'Suit regular';
  font-weight: 500;
  width: 100%;
	height: 35px;
  color: white;
  .ant-select-dropdown {
		background-color: #B2B2B2 !important;
		}
  .ant-select-selector {
    width: 100% !important;
    height: 35px;
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid #B1AEB5 !important;
    border-radius: 0px !important; 
		color: white;
		${({ theme }) =>
    theme.media.monitor(
      `
			height: 52px !important;
			font-size: 18px !important;
      `,
    )}
      ${({ theme }) =>
      theme.media.tablet(
      `
        width:100% !important;
      `,
      )}
    ${({ theme }) =>
      theme.media.mobile(
      `
        width: 100% !important;
      `,
      )}
  }
  .ant-select-clear{
    background-color: transparent !important; 
  }
  .ant-select-selection-item {
    color: white;
  }
  .ant-select-selection-placeholder, .ant-select-selection-search-input{
    display: flex !important;
    font-size: 14px !important;
    color: #BFBFBF !important;
    align-items: center !important;
		${({ theme }) =>
    theme.media.monitor(
      `
				font-size: 18px !important;
      `,
    )}
  }
  &:hover {
    border: none !important;
    border-bottom: 1px solid #B1AEB5 !important; 
    border-color: #B2B2B2;
  }
  &:focus {
    outline: none !important;
  }
	span {
		color: white;
	}
	${({ theme }) =>
    theme.media.monitor(
      `
			height: 52px !important;
			font-size: 18px !important;
      `,
    )}
`;

export const NumberRangeDiv = styled.div`
	font-family: 'Suit regular'!important;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	column-gap: 10px;
	border-radius: 15px;
	width: 100%;
	${({ theme }) =>
    theme.media.tablet(
      `
			column-gap: 3px;
      `,
    )}
			${({ theme }) =>
    theme.media.mobile(
      `
			column-gap: 2px;
      `,
    )}
`

export const StyledInput = styled.input`
	font-family: 'Suit regular'!important;
	font-size: 14px;
	width: 100%;
	height: 35px;
	padding: 4px 11px 4px;
	color: #BFBFBF;
	background: transparent;
	border-radius: 0px;
	border: none;
	border-bottom: 1px solid #B1AEB5;
	::placeholder {
		color: #BFBFBF;
	}
	${({ theme }) =>
    theme.media.monitor(
      `
			height: 52px;
			font-size: 18px;
      `,
    )}
`

export const Range = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	font-family: 'Suit regular'!important;
	font-size: 14px;
	color: #BFBFBF;
	font-size: 18px;
	font-weight: bold;
	height: 35px;
	${({ theme }) =>
		theme.media.monitor(
			`
			font-size: 22px;
			height: 52px;
			`,
		)}
`

export const StyledButton = styled(Button)`
	width: 100%;
	background: #5A27DB;
	border-radius: 8px;
	border: none;
	color: white;
	font-family: 'Suit medium'!important;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	margin-top: 2px;
	${({ theme }) =>
    theme.media.monitor(
      `
			padding-top: 5px;
			padding-bottom: 5px;
			height: 52px;
			font-size: 18px;
      `,
    )}
	:hover {
		color: white !important;
	}
`

export const StyledCameraButton = styled(Button)`
	background: #5A27DB;
	border-radius: 8px;
	border: none;
	color: white;
	font-family: 'Suit medium'!important;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	margin-top: 2px;
	:hover {
		color: white !important;
	}
`