import { MainLayout, Spinner } from '@/components';
import { NotFound, useAuthInfo } from '@/features';
import { lazyImport } from '@/utils';
import { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const { MainRoutes } = lazyImport(() => import('@/features/main'), 'MainRoutes'); 
const { CarDataRoutes } = lazyImport(( ) => import('@/features/cardata'), 'CarDataRoutes');
const { CarListRoutes } = lazyImport(() => import('@/features/carlist'), 'CarListRoutes');
const { PhotoShootRoutes } = lazyImport(() => import('@/features/photoshoot'), 'PhotoShootRoutes');
const { MonitoringRoutes } = lazyImport(() => import('@/features/monitoring'), 'MonitoringRoutes');

export function Protected() {
  const { authInfo } = useAuthInfo();
  const username = authInfo?.username;
  
  function Protected() {
    const location = useLocation();
    if (!username) {
      return <Navigate to="/" state={{ redirect: location.pathname }} />;
    }

    return (
      <MainLayout>
        <Suspense fallback={<Spinner />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    );
  }

  const ProtectedRoutes = [
    {
      path: '/search',
      element: <Protected />,
      children: [
        {
          path: '',
          element: <MainRoutes />,
        },
        {
          path: ':vrn/:num?',
          element: <CarDataRoutes />,
        },
      ],
    },
    {
      path: '/carlist',
      element: <Protected />,
      children: [
        {
          path: '',
          element: <CarListRoutes />,
        },
      ],
    },
    {
      path: '/photoshoot',
      element: <Protected />,
      children: [
        {
          path: '',
          element: <PhotoShootRoutes />,
        },
      ],
    },
    {
      path: '/monitoring',
      element: <Protected />,
      children: [
        {
          path: '',
          element: <MonitoringRoutes />,
        },
      ],
    },
    {
      path: '*',
      element: <Protected />,
      children: [
        {
          path: '',
          element: <NotFound />,
        },
      ],
    },
  ];

  return { ProtectedRoutes };
}
