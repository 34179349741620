import styled from "styled-components";

export const MainPageContainer = styled.main`
  width: 95%;
  margin: 0 auto;
  ${({ theme }) =>
    theme.media.mobile(
      `
      width: 100%;
      margin-bottom: 30px;
      `,
    )}
    button {
      color: white;
    }
`;

export const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  font-family: 'Suit bold';
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 40px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 34px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
      font-size: 18px;
      `,
    )}
`;

export const SubTitle = styled.div`
  width: 85%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: 'Suit medium';
  font-size: 16px;
  color: #ffffffdd;
  font-weight: 600;
  margin: 0 auto;
  margin-top: 30px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 28px;
      `,
    )}
`;

export const StyledWarning = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.71);
  font-family: 'Suit medium';
  font-size: 20px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 32px;
        height: 600px;
      `,
    )}
`;

export const Scratch = styled.div`
  position:absolute;
  background: rgba(90, 39, 219, 0.31);
  border: 4px solid #5A27DB;
  width: 45px;
  height:45px;
  ${({ theme }) =>
    theme.media.tablet(
      `
        width: 45px;
        height:45px;
      `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
        width: 12px;
        height:12px;
        border: 1px solid #5A27DB;
      `,
    )}
`;

export const Separated = styled.div`
  position:absolute;
  background: rgba(107, 225, 222, 0.5);
  border: 4px solid #6BE1DE;
  width: 45px;
  height:45px;
    ${({ theme }) =>
    theme.media.mobile(
      `
        width: 12px;
        height:12px;
        border: 1px solid #6BE1DE;
      `,
    )}
`;

export const Crushed = styled.div`
  position:absolute;
  background: rgba(199, 255, 108, 0.5);
  border: 4px solid #C7FF6C;
  width: 45px;
  height:45px;  
  ${({ theme }) =>
    theme.media.tablet(
      `
        width: 45px;
        height:45px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
        width: 12px;
        height:12px;
        border: 1px solid #C7FF6C;
      `,
    )}
  `;

export const Breakage = styled.div`
  position:absolute;
  background: rgba(255, 48, 185, 0.5);
  border: 4px solid #FF30B9;
  width: 45px;
  height:45px;
  ${({ theme }) =>
    theme.media.tablet(
      `
        width: 45px;
        height:45px;
      `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
        width: 12px;
        height:12px;
        border: 1px solid #FF30B9;
      `,
    )}
`;

export const StyledDiv = styled.div`
  box-sizing: border-box;
  width: 88%;
  height: 100%;;
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: center;
  margin: 0 auto;
  margin-top: 0px;
  grid-column-gap: 2%;
  ${({ theme }) =>
    theme.media.mobile(
      `
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 1vw;
      row-gap: 65px;
      `,
    )}
`; 

export const StyledInContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
`

export const StyledInDiv = styled.div`
  box-sizing: border-box;
  width: 88%;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  justify-content: center;
  margin: 0 auto;
  margin-top: 60px;
  grid-column-gap: 2%;
  grid-row-gap: 2%;
  ${({ theme }) =>
    theme.media.mobile(
      `
      width: 90%;
      grid-template-columns: 49% 49%;
      grid-column-gap: 2%;
      grid-row-gap: 2%;
      margin-top: 30px;
      `,
    )}
`; 

export const StyledBefAft = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 1vw;
  display: flex;
  flex-direction: column;
`;

export const TypeText = styled.div`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  font-family: 'Suit medium','Digital Sans Now Ml Pro bold';
  margin: 0 auto;
  margin-top: 2vw;
  margin-bottom: 3vw;
  div {
    margin-left: 20px;
  }
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 33px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
      display: flex;
      flex-direction: row;
      font-size: 16px;
      `,
    )}
`;

export const TypeInText = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  font-family: 'Suit medium';
  margin-top: 2vw;
  display: flex;
  width: 88%;
  justify-content: flex-end;
  margin-bottom: 10px;
  div {
    margin-left: 5px;
  }
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 28px;
      `,
    )}
`;

export const TitleGroup = styled.div`
  width: 88%;
  display: flex;
  flex-direction: row;
  column-gap: 0px;
  margin: 0 auto;
  margin-top: 50px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 70px;
      `,
    )}
`;

export const TitleContent = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  font-family: 'Suit extra bold';
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.87);
  float: left;
  margin-right: 10px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 32px;
      `,
    )}
  ${({ theme }) =>
      theme.media.mobile(
      `
        font-size: 18px;
      `,
      )}
    cursor: pointer;
`;

export const VehicleImageGrid = styled.div`
  display: grid;
  margin: 0 auto;
  width: 100%;
  grid-template-columns: 48% 48%;
  row-gap: 1vw;
  column-gap: 1vw;
  justify-content: center;
  align-items:center;
  ${({ theme }) =>
    theme.media.mobile(
      `
        height: 590px;
      `,
    )}
  
`;

export const StyledDivLine = styled.div`
  width: 85%;
  height: 2px;
  display: flex;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 10px;
  border-radius: 5px;
  ${({ theme }) =>
    theme.media.mobile(
      `
        display: none;
      `,
    )}
`;

export const CreatedAt = styled.div`
    display: flex;
    width: 93%;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.3);
    font-family: 'Suit medium', 'Inter medium';
    margin-bottom: 10px;
    ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 22px;
      `,
    )}
`;

export const NonContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  margin-bottom: 8vw;
  height: 140px;
  ${({ theme }) =>
    theme.media.mobile(
      `
      position: inherit !important;
      transform: none !important;
      margin-top: 20px;
      height: 140px;
      `,
    )}
`;

export const RentalIcon = styled.div`
  width: 50px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/assets/images/rental.png');
  background-size: cover;
  ${({ theme }) =>
    theme.media.monitor(
      `
        width: 70px;
        height: 112px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
        width: 30px;
        height: 30px;
      `,
    )}
`

export const ReturnIcon = styled.div`
  width: 50px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/assets/images/return.png');
  background-size: cover;
  ${({ theme }) =>
    theme.media.monitor(
      `
        width: 70px;
        height: 112px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
        width: 30px;
        height: 30px;
      `,
    )}
`

export const NonText = styled.div`
    font-family: 'Suit bold';
    font-weight: 700;
    font-size: 1.5vw;
    text-align: center;
    color: rgba(255, 255, 255, 0.71);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ theme }) =>
      theme.media.monitor(
        `
        font-size: 28px;
        `,
      )}
    ${({ theme }) =>
      theme.media.mobile(
        `
        font-size: 16px;
        height: 70px;
        `,
      )}
`

export const StyledBtnDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width :86%;
  margin: 0 auto;
  margin-top: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
` 

export const StyledButton = styled.button`
width: 187px;
height: 50px;
background: #5A27DB;
border-radius: 8px;
font-size: 20px;
color: white;
font-family: 'Suit semi bold';
float: right;
margin-top: 20px;
${({ theme }) =>
  theme.media.mobile(
    `
    margin-top: 30px;
    font-size: 14px;
    width: 127px;
    height: 40px;
    margin-bottom: 20px;
    `,
  )}
`