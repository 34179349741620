import { useQuery } from 'react-query';
import { FETCH_VEHICLE_DIAG_QUERY_KEY, FETCH_PAGINATION_QUERY_KEY } from '@/constants';
import { axiosInstance } from '@/configs/axios';
import { handleQueryError } from '@/utils';

type Meta = {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
};

export type VehicleDiagSummaryType = {
  inspection_id: number;
  direction: string;
  createdAt: string;
  station_name: string;
  sum_breakage_num: number | null;
  sum_crushed_num: number| null;
  sum_scratch_num: number| null;
  sum_separated_num: number| null;
  sum_bbox_num: number| null;
};

export const getVehicleDiagSummary = async (
  vehicle_id: number | undefined,
  pagination: number | undefined,
  limit: number | undefined
): Promise<{ success: boolean; items: VehicleDiagSummaryType[]; meta: Meta }> => {
  const response = await axiosInstance.get<{ success: boolean; items: VehicleDiagSummaryType[]; meta: Meta }>(
    `/api/v1/inspections/results/vehicles/${vehicle_id}`,
    {
      params: {
        page: pagination,
        limit: limit,
      },
    }
  );
  return response.data;
};

export function useGetVehicleDiagSummary(vehicle_id: number, pagination: number, limit: number, type: boolean) {
  const queryKey = type ? FETCH_VEHICLE_DIAG_QUERY_KEY : FETCH_PAGINATION_QUERY_KEY;

  return useQuery(
    [queryKey, vehicle_id, pagination, limit],
    () => getVehicleDiagSummary(vehicle_id, pagination, limit),
    {
      onError: handleQueryError,
      retry: false,
      select: (data) => {
        const transformedItems = data.items.map(item => ({
          ...item,
          createdAt: item.createdAt.slice(0, 10) + ' (' + item.createdAt.slice(11, 16) + ')',
          sum_crushed_num: item.sum_crushed_num === 0 ? null : item.sum_crushed_num,
          sum_scratch_num: item.sum_scratch_num === 0 ? null : item.sum_scratch_num,
          sum_breakage_num: item.sum_breakage_num === 0 ? null : item.sum_breakage_num,
          sum_separated_num: item.sum_separated_num === 0 ? null : item.sum_separated_num,
          sum_bbox_num: item.sum_bbox_num === 0 ? null : item.sum_bbox_num
        }));

        return {
          ...data,
          items: transformedItems,
        };
      },
      cacheTime: 1000 * 60 * 5, // 5 minutes
      staleTime: 1000 * 60 * 1, // 1 minute
    }
  );
}