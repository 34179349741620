import { NotFoundContainer, StyledButton } from './styles';
import { useNavigate } from 'react-router-dom';
import { useAuthInfo } from '@/features/auth';

export function NotFound() {
  const navigate = useNavigate();
  const { authInfo } = useAuthInfo();
  const username = authInfo?.username;

  const handleClick = () => {
    if (username) {
      navigate('/search');
    } else {
      navigate('/');
    }
  };

  return (
    <NotFoundContainer>
      <h2>Not Found</h2>
      <span>요청하신 페이지를 찾을 수 없습니다.</span>
      <StyledButton onClick={handleClick}>홈페이지로 이동</StyledButton>
    </NotFoundContainer>
  );
}
