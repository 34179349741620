import styled from "styled-components";
import { Form, Input,Pagination } from "antd";

export const StyledForm = styled(Form)`
	margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 50px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        row-gap: 80px;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
        row-gap: 30px;
      `,
    )}
`

export const InspectionText = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-family: 'Suit medium', 'Inter medium', sans-serif;
  color: #ffffff;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 19px;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
        font-size: 12px;  
      `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
        font-size: 10px;  
      `,
    )}
`;

export const StyledGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  height: 100%;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
      theme.media.mobile(
      `
        height: 270px;
        margin-bottom: 10px;
      `,
    )}
`;

export const StyledItem = styled.div`
  width: 100%;
`;

export const BarChartContainer = styled.div<{ chartWidth?: number }>`
width: ${(props) => props.chartWidth}px; /* 차트의 가로 크기를 props로 설정 */
height: 100%;
`;

export const StyledInput = styled(Input)`
	background: rgba(150, 150, 150, 0.4);
	border-radius: 5px;
	border: none;
	:hover { 
		background: rgba(150, 150, 150, 0.6);
	}
`

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #ffffffaf;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Suit medium', 'Inter medium';
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 28px;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
        font-size: 20px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      ` 
        margin-top: 5px;
        font-size: 14px;
      `,
    )}
`;

export const ContentSubTitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #AAAAAA;
  font-family: 'Suit regular', 'Inter medium';
  margin-bottom: 20px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      ` 
        font-size: 10px;
      `,
    )}
`

export const StyledVehicleInOut = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
`;

export const ContentTitle = styled.div`
  width: 100%;
  display: flex;
  margin-left: 13px;
  justify-content: flex-start;
  font-size: 17px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  font-family: 'Suit medium', 'Inter medium';
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 24px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      ` 
        margin-top: 5px;
        font-size: 10px;
        margin-left: 5px;
      `,
    )}
`


export const ContentText = styled.div`
  width: 100%;
  display: flex;
  margin-left: 13px;
  padding: 5px;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Suit medium', 'Inter medium';
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 22px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      ` 
        margin-top: 5px;
        font-size: 10px;
        margin-left: 0px;
        padding: 2px;
      `,
    )}
`

export const GridSearchList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const Total = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  font-family: 'Suit medium', 'Inter medium';
  ${({ theme }) =>
    theme.media.mobile(
      ` 
        font-size: 16px;
      `,
    )}
`

export const StyledText = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: #1c1c1ce7;
  font-family: 'Suit medium', 'Inter medium';
  ${({ theme }) =>
    theme.media.mobile(
      ` 
        font-size: 14px;
      `,
    )}
`;

export const GridRowListItem = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 26% 18% 10% 13% 33%;
  padding: 0px 10px;
  div {
    font-size: 15px;
    font-family: 'Suit medium', 'Inter medium';
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
    ${({ theme }) =>
      theme.media.mobile(
      `
        grid-template-columns: 26% 18% 10% 14% 32%;
        font-size: 10px;
        padding: 1px;
      `,
    )}
  }
  ${({ theme }) =>
      theme.media.mobile(
      `
        grid-template-columns: 26% 18% 10% 14% 32%;
      `,
    )}
`

export const ListContainer = styled.div`
  height: 200px;
`

export const GridListItem = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 26% 18% 10% 13% 33%;
  border-top: 1px solid #c1c1c15e;
  padding: 10px;
  border-bottom: 1px solid #c1c1c15e;
  div {
    font-size: 14px;
    font-family: 'Suit medium', 'Inter medium';
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
    ${({ theme }) =>
      theme.media.mobile(
      `
        font-size: 12px;
        padding: 1px;
      `,
    )}
  }
`

export const StyledPagination = styled(Pagination)`
color: rgba(255, 255, 255, 0.5) !important;
.ant-pagination{
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  color: rgba(255, 255, 255, 0.5) !important;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
}
svg {
  color: #ffffff !important;

}
.ant-pagination-item-active{
  border-color: transparent !important;
  background-color: transparent !important;
}
.ant-pagination-item-active a{
  color: #ffffff !important;
  font-weight: 700;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
}
.ant-select-selector {
  background: transparent !important;
  border: none !important;
}
.ant-select-selection-item{
  color: rgba(255, 255, 255, 0.6) !important;
}
`;

export const ScrollButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 100px;
      `,
    )}
`;