import { Header } from '@/components';
import { StyledLayout, StyledContent } from './styles';

type MainLayoutProps = {
  children: React.ReactNode;
};

const backgroundImgStyle = {
  backgroundImage: `url("assets/images/backgroundImg.png")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  overflow: 'hidden',
  heigth: '100vh',
};

export function MainLayout({children}: MainLayoutProps) {
  const path = location.pathname == '/search' ? true : false;       // 현재 페이지의 path가 '/search'인지 확인 
  return (
    <StyledLayout style={ path ? backgroundImgStyle : undefined}>   {/*/search' 페이지일 경우 배경 이미지 적용 */}
      <Header />
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
  );
}