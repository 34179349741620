import { Layout, Menu } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  width: 100vw;
  height: 58px;
  padding: 0px 42px;
  position: fixed;
  z-index: 2001;
  background: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  background-color: #000000;
  ${({ theme }) =>
    theme.media.monitor(
      `
       height: 70px;
       padding: 0px 60px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
       padding: 0px 22px;
      `,
    )}
`;

export const MenuBlock = styled.div`
  display: flex;
  gap: 40px;
  height: 100%;
  ${({ theme }) =>
    theme.media.mobile(
      `
       gap: 6px;
      `,
    )}
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
`;

export const Logo = styled.img`
  width: 113px;
  height: auto;
  margin: 0 auto;
  :hover {
    cursor: pointer;
  }  
  ${({ theme }) =>
    theme.media.monitor(
      `
        width: 160px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
       width: 70px;
       margin: inherit;
      `,
    )}
`;

export const StyledMenu = styled(Menu)`
  background: transparent;
  height: 100%;
  border: none;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-menu-item,
  .ant-menu-submenu {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 10px !important;
    color: #CBCBCB !important;
    font-family: 'Suit medium';
    font-weight: 500;
    font-size: 14px;
    ${({ theme }) =>
      theme.media.monitor(
        `
          font-size: 20px;
        `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
       width: 70px;
       font-size: 10px;
      `,
    )}
  }

  .ant-menu-item-active::after,
  .ant-menu-item-selected::after,
  .ant-menu-submenu-active::after,
  .ant-menu-submenu-selected::after {
    border-bottom-width: 0px !important;
    border-bottom-color: transparent !important;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-submenu-active,
  .ant-menu-submenu-selected {
    font-family: 'Suit semi bold';
    font-size: 14px;
    color: #ffffff !important;
    ${({ theme }) =>
      theme.media.monitor(
        `
          font-size: 20px;
        `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
       font-size: 10px;
      `,
    )}
  }
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 70%;
  padding: 0 15px 0 5px;
  border-radius: 10px;
  font-weight: 400;
  color: white;
  white-space: nowrap;
  font-size: 16px;

  :hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
  }    
  ${({ theme }) =>
      theme.media.monitor(
        `
          font-size: 22px;
        `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
       font-size: 12px;
       padding: 0px;
      `,
    )}  
`;

export const MobileStyle = styled.div`
    position: absolute;
    width: 100%;
`

export const ToggleBtn = styled.div`
  height: 100%;
  position: absolute;
  right: 18px;
  top: 28px;
`;

export const StyledToggleContainer = styled.div`
  position: absolute;
  height: 95vh;
  top: 68px;
  background: black;
  z-index: 2000;
`

export const StyledMenuData = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledToggleMenu = styled(Menu)`
  width: 100vw !important;
  display: flex;
  flex-direction: column;
  font-family: 'Suit regular';
  font-size: 18px;
  font-weight: 200;
  align-items: start;
  background-color: black;
  margin-top: 40px;
  span { 
    font-family: 'Suit regular';
    font-size: 18px;  
    font-weight: 200; 
    color: rgba(255, 255, 255, 0.70);
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    color: #ffffff !important;
  }
  .ant-menu-item,
  .ant-menu-submenu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 10px !important;
    font-family: 'Suit medium';
    font-size: 15px;
  }

  .ant-menu-item-active::after,
  .ant-menu-item-selected::after,
  .ant-menu-submenu-active::after,
  .ant-menu-submenu-selected::after {
    border-bottom-width: 0px !important;
    border-bottom-color: transparent !important;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-submenu-active,
  .ant-menu-submenu-selected {
    color: #ffffff !important;
    font-family: 'Suit medium';
    font-weight: 500;
    background-color: transparent !important;
    span {
      color: #ffffff !important;
      font-family: 'Suit medium';
      font-weight: 500;
    }
  }

  .ant-menu-submenu {
    div {
      color: #ffffff !important;
    }
  }

  :hover {
    background-color: transparent !important;
    cursor: pointer;
  }
`

export const LogoutBtn = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 24px;
  padding-bottom: 65px;
  p { 
    font-family: 'Suit regular';
    font-size: 18px;
    padding-left: 10px;
    color: white;
  }
`

