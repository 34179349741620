import styled from "styled-components";

export const StyleResult = styled.div`
  width: 86%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  row-gap: 0px;
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  ${({ theme }) =>
      theme.media.monitor(
      `
        margin-top: 20px;
      `,
    )}
      ${({ theme }) =>
      theme.media.mobile(
      `
        height: 210px;
        justify-content: space-between;
      `,
    )}
`;

export const DamageGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 30px;  
  ${({ theme }) =>
    theme.media.mobile(
      `
        flex-direction: column;
        row-gap: 5px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
        padding: 13px;
        row-gap: 0px;
      `,
    )}
`;

export const DamageTitle = styled.div`
  font-size: 22px;
  font-weight: 500;
  font-family: 'Inter medium', 'Suit medium';
  display: flex;
  color: #ffffff;
  p {
    color: #F12304;
    font-weight: 600;
  }
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 24px;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
      font-size: 19px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
      font-size: 12px;
      `,
    )}
`;

export const SubTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  font-family: 'Suit medium', 'Inter medium';
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 28px;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
        font-size: 20px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      ` 
        font-size: 14px;
      `,
    )}
`;

export const StyledResultCar = styled.div`
  width: 70%;
  display: grid;
  grid-template-columns: 47% 47%;
  grid-column-gap: 6%;
  margin: 0 auto;
`;

export const CarList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Car = styled.img`
  width: 100%;
  height: 100%;
`;

export const DamageCar = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const GridCar = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`

export const StyleResultText = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  ${({ theme }) =>
      theme.media.monitor(
      `
        column-gap: 20px;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
        column-gap: 10px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
        width: 100%;
        column-gap: 5px;
      `,
    )}
`;

export const InspectionColor = styled.div`
  width: 13px;
  height: 13px;
  ${({ theme }) =>
    theme.media.tablet(
      `
        width: 10px;
        height: 10px;
      `,
    )}
      ${({ theme }) =>
    theme.media.mobile(
      `
        width: 8px;
        height: 8px;
      `,
    )}
`;

export const InspectionText = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Suit medium', 'Inter medium', sans-serif;
  color: #ffffff;
  ${({ theme }) =>
      theme.media.monitor(
      `
        font-size: 24px;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
        font-size: 14px;  
      `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
        font-size: 8px;  
      `,
    )}
`;

export const StyledButton = styled.button`
  background-color: transparent;
  color: white;
  border: white 1px solid;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Suit medium';
  ${({ theme }) =>
    theme.media.monitor(
      `
        font-size: 20px;
      `,
    )}
`;