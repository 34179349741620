import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

type ItemWithIndex = {
  index: number;
  createdAt: string;
  sum_crushed_num: number | null;
  sum_scratch_num: number | null;
  sum_breakage_num: number | null;
  sum_separated_num: number | null;
  sum_bbox_num: number | null;
  inspection_id: number;
  direction: string;
  station_name: string;
};

type LineChartProps = {
  data: ItemWithIndex[] | undefined;
  inspec_id: number;
  handleUpLimit: () => void;
  handleDownLimit: () => void;
  handleSlideRight?: () => void;
  handleSlideLeft?: () => void;
};

export function LineChart({ data, inspec_id, handleUpLimit, handleDownLimit, handleSlideLeft, handleSlideRight }: LineChartProps) {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const width = 1000;
  const height = 400;
  const margin = { top: 20, right: 30, bottom: 50, left: 40 };

  useEffect(() => {
    if (!data) return;

    const size = window.innerWidth < 700 ? 'mobile' : window.innerWidth < 1225 ? 'tablet' : window.innerWidth < 1800 ? 'notebook' : window.innerWidth < 2100 ? 'desktop' : 'bigdesktop';
    const svg = d3.select(svgRef.current).attr('viewBox', `0 0 ${width} ${height}`);
    const tooltip = d3.select(tooltipRef.current);

    const xScale = d3
      .scaleBand<number>()
      .domain(data.map((d) => d.index) ?? [])
      .range([margin.left, width - margin.right])
      .padding(0.3);

    const maxBboxNum = d3.max(data, (d) => (d.sum_bbox_num !== null ? +d.sum_bbox_num : 0)) || 0;
    const yMax = maxBboxNum + 2;

    const yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([height - margin.bottom, margin.top])
      .nice();

    const xAxis = (g: d3.Selection<SVGGElement, unknown, null, undefined>) =>
      g
        .attr('transform', `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(xScale).tickFormat(d3.format('d')))
        .selectAll('text')
        .attr('fill', (d, i) => {
          console.log(d);
          const dataItem = data[i];
          return dataItem && dataItem.inspection_id === inspec_id ? '#19E5DF' : 'rgb(157, 157, 157)';
        })
        .style('font-family', 'Inter medium')
        .style('font-size', '13px')
        .attr('dy', '1.3em');

    const yAxis = (g: d3.Selection<SVGGElement, unknown, null, undefined>) =>
      g
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScale).ticks(yMax).tickSize(-width + margin.left + margin.right))
        .call((g) => g.select('.domain').remove())
        .selectAll('text')
        .attr('fill', 'rgb(157, 157, 157)')
        .style('font-family', 'Inter medium')
        .style('font-size', '13px');

    svg.selectAll('*').remove();
    svg.append<SVGGElement>('g').call(xAxis);
    svg.append<SVGGElement>('g').call(yAxis);

    const line = d3
      .line<ItemWithIndex>()
      .x((d) => xScale(d.index)! + xScale.bandwidth() / 2)
      .y((d) => yScale(d.sum_bbox_num !== null ? +d.sum_bbox_num : 0))
      .curve(d3.curveMonotoneX);

    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'rgb(157, 157, 157)')
      .attr('stroke-width', 2)
      .attr('d', line);

    svg
      .append('g')
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', (d) => xScale(d.index)! + xScale.bandwidth() / 2)
      .attr('cy', (d) => yScale(d.sum_bbox_num !== null ? +d.sum_bbox_num : 0))
      .attr('r', (d) => (d.inspection_id === inspec_id ? 7 : 6.5))
      .attr('fill', (d) => (d.inspection_id === inspec_id ? '#19E5DF' : '#A9A8A8'))
      .attr('fill', (d) => (d.inspection_id === inspec_id ? '#19E5DF' : '#A9A8A8'))
      .on('mouseover', function (event, d) {
        console.log(event);
        console.log(event);
        d3.select(this).attr('fill', '#ffffff');

        const circleX = +d3.select(this).attr('cx');
        const circleY = +d3.select(this).attr('cy');

        const tooltipStyles = {
          mobile: {
            fontSize: '9px',
            padding: '5px',
            tooltipWidth: 340
          },
          tablet: {
            fontSize: '12px',
            padding: '10px',
            tooltipWidth: 220
          },
          notebook: {
            fontSize: '14px',
            padding: '10px',
            tooltipWidth: 105
          },
          desktop: {
            fontSize: '18px',
            padding: '10px',
            tooltipWidth: 190
          },
          bigdesktop: {
            fontSize: '20px',
            padding: '10px',
            tooltipWidth: -70
          }
        };

        const { fontSize, padding, tooltipWidth } = tooltipStyles[size];
        const gapAbovePoint = 15;
        const offsetX = 20;

        let tooltipX = circleX - tooltipWidth / 2;
        let tooltipY = circleY - gapAbovePoint - 40;

        const svgWidth = width - margin.left - margin.right;
        const svgHeight = height - margin.top - margin.bottom;

        if (d.index < data.length / 2) {
          tooltipX = circleX + offsetX;
          if(size === 'bigdesktop') {
            tooltipX  = tooltipX + 100;
            if(d.index >= (data.length / 2) / 2) tooltipX = tooltipX + 120;
          }
          tooltipX = Math.min(tooltipX, svgWidth - tooltipWidth);
          if(size === 'mobile') {
            tooltipX = tooltipX - tooltipWidth + 258;
            if(d.index >= (data.length / 2) / 2) tooltipX = tooltipX - 160;
          }
        } else {
          tooltipX = circleX - (tooltipWidth + tooltipWidth  + offsetX);
          tooltipX = Math.max(tooltipX, 0);
          if(size === 'bigdesktop') {
            if(d.index < ((data.length / 2) / 2) + data.length / 2) tooltipX = tooltipX + 200;
          }
        }

        tooltipY = Math.max(tooltipY, 0);
        tooltipY = Math.min(tooltipY, svgHeight - 40);
        if(size === 'mobile') tooltipY = tooltipY - 120;
        tooltip
          .style('opacity', 1)
          .style('left', `${tooltipX}px`)
          .style('top', `${tooltipY}px`)
          .style('font-size', fontSize)
          .style('padding', padding)
          .html(`
            <div style="font-family: 'Inter medium', sans-serif; border-radius: 8px;">
              <strong style="display: block; margin-bottom: 2px; font-weight: 600">${d.createdAt}</strong><br/>
              <strong style="display: block; font-weight: 600;">Total: ${d.sum_bbox_num ?? '0'}</strong>
              <strong style="display: block; font-weight: 600;">Total: ${d.sum_bbox_num ?? '0'}</strong>
              <strong>Scratch:</strong> ${d.sum_scratch_num ?? '0'}<br/>
              <strong>Separated:</strong> ${d.sum_separated_num ?? '0'}<br/>
              <strong>Crushed:</strong> ${d.sum_crushed_num ?? '0'}<br/>
              <strong>Breakage:</strong> ${d.sum_breakage_num ?? '0'}
            </div>
          `);
      })
      .on('mouseout', function (event, d) {
        console.log(event);
        console.log(event);
        d3.select(this).attr('fill', d.inspection_id === inspec_id ? '#19E5DF' : 'rgb(157, 157, 157)');
        tooltip.style('opacity', 0);
      });

    svg.selectAll('.domain, .tick line').attr('stroke', '#ffffff24');
  }, [data, inspec_id]);
  
  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          opacity: 0,
          backgroundColor: '#fff',
          padding: '10px',
          borderRadius: '8px',
          border: '1px solid #ccc',
          pointerEvents: 'none',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          fontFamily: 'Inter medium, sans-serif',
          fontSize: '10px',
        }}
      />
      <svg ref={svgRef} style={{ width: '100%', height: '100%' }} />
      <div style={{ position: 'absolute', top: '-20px', right: '10px', display: 'flex', gap: '10px' }}>
        <button
          style={{
            background: '#19e5de0',
            border: 'none',
            borderRadius: '5px',
            color: '#fff',
            cursor: 'pointer',
          }}
          onClick={handleUpLimit}
        >
          <FiPlusCircle color='white' fontSize={'1.5vw'}/>
        </button>
        <button
          style={{
            background: '#19e5de0',
            border: 'none',
            borderRadius: '5px',
            color: '#fff',
            cursor: 'pointer',
          }}
          onClick={handleDownLimit}
        >
          <FiMinusCircle color='white' fontSize={'1.5vw'}/>
        </button>
      </div>
      <div style={{ width: '100%', position: 'absolute', top: '40%', display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
        <button
          style={{
            background: '#19e5de0',
            border: 'none',
            borderRadius: '5px',
            color: '#fff',
            cursor: 'pointer',
          }}
          onClick={handleSlideLeft}
        >
          <FaCaretLeft color='white' fontSize={'1.5vw'}/>
        </button>
        <button
          style={{
            background: '#19e5de0',
            border: 'none',
            borderRadius: '5px',
            color: '#fff',
            cursor: 'pointer',
          }}
          onClick={handleSlideRight}
        >
          <FaCaretRight color='white' fontSize={'1.5vw'}/>
        </button>
      </div>
    </div>
  );
}