import styled from 'styled-components';
import { Button, Form, Input } from 'antd';

export const SigninDiv = styled.div`
  margin: 0px auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8vw;
  ${({ theme }) =>
    theme.media.monitor(
      `
      margin-top: 12vw;
      
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
      margin-top: 12vw;
      
      `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
        margin-top: 17vw;
      `,
    )}
`;

export const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 427px 120px;
  grid-column-gap: 18px;
  ${({ theme }) =>
    theme.media.monitor(
      `
        grid-template-columns: 600px 150px;
        grid-column-gap: 30px;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
        grid-template-columns: 370px 86px;
        grid-column-gap: 10px;
      `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 14px;
      `,
    )}
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

export const StyledFormItem = styled(Form.Item)`
  width: 100%;
  display: flex;
  margin: 0px;
`;

export const StyledInput = styled(Input)`
  font-family: 'Suit medium';
  font-size: 22px;
  border: none;
  color: #FFFFFF;
  width: 427px;
  height: 53px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  &:hover {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  &:focus {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.7) !important;
    font-size: 22px;
  }
  ${({ theme }) =>
    theme.media.monitor(
      `
        font-size:30px;
        width: 600px;
        height: 75px;
        &::placeholder {
          font-size: 30px;
        }
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
      width: 370px;
      `,
    )}
    ${({ theme }) =>
    theme.media.mobile(
      `
        width: 310px;
      `,
    )}
`;

export const StyledButton = styled(Button)`
  width: 100px;
  height: 120px !important;
  background: #5A27DB;
  font-family: 'Inter medium';
  font-size: 24px !important;
  border: none !important;
  &:disabled {
    font-family: 'Inter medium';
    background: #422A7F !important;
    border: none !important;
    color: rgba(255, 255, 255, 0.48);
    font-size: 24px;
  }
  &:focus {
    border: none !important;
  }
  &:not(:disabled):hover {
    background: #5A27DB !important;
    border: none !important;
  }
  ${({ theme }) =>
    theme.media.monitor(
      `
        width: 150px;
        height: 165px !important;
        font-size: 30px !important;
      `,
    )}
  ${({ theme }) =>
    theme.media.tablet(
      `
       width: 86px;
      `,
    )}
  ${({ theme }) =>
    theme.media.mobile(
      `
       width: 310px;
       height: 53px !important;
      `,
    )}
`;
