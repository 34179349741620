import { Spinner } from '@/components';
import { lazyImport } from '@/utils';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const { IntroRoutes } = lazyImport(() => import('@/features/intro'), 'IntroRoutes');
const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes');
const { NotFound } = lazyImport(() => import('@/features/notFound'), 'NotFound');

export function Public() {
  return (
    <Suspense fallback={<Spinner />}>
      <Outlet />
    </Suspense>
  );
}

export const PublicRoutes = [
  {
    path: '',
    element: <Public />,
    children: [
      {
        path: '',
        element: <IntroRoutes />,
      },
    ],
  },
  {
    path: '/signin',
    element: <Public />,
    children: [
      {
        path: '',
        element: <AuthRoutes />,
      },
    ],
  },
  {
    path: '*',
    element: <Public />,
    children: [
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];
