import { Form } from 'antd';
import { MainModal } from '@/components';
import VehicleHistory from '../vehicleHistory/VehicleHistory';
import { Summary, VehicleInOutType } from '../VehicleImage/types';
import { memo, useCallback } from 'react';

type VehicleHistoryModalProps = {
  vehicle_id: number;
  open: boolean;
  carnum: String;
  handleClose: () => void;
  inspec_id: number;
  InspectionTitle: number[];
  combinedSummaryFront: Summary;
  combinedSummaryBack: Summary;
  InspectionColorPoint: string[];
  vehicleInOutData: VehicleInOutType;
  totalDamage: number;
  beforeTotalDamage: number;
};

export const VehicleHistoryModal = memo(({
  vehicle_id,
  open, 
  carnum,
  handleClose,
  inspec_id,
  InspectionColorPoint, InspectionTitle, combinedSummaryBack, combinedSummaryFront, vehicleInOutData, totalDamage, beforeTotalDamage
}: VehicleHistoryModalProps) => {
  const [form] = Form.useForm();

  const resetForm = useCallback(() => {
    form.resetFields();
  }, [carnum]);

  return (
    <MainModal
      title={`차량번호 : ${carnum}`}
      open={open}
      onCancel={handleClose}
      footer={null}
      afterClose={resetForm}
      closeIcon={true}
      destroyOnClose={false} // Ensures the modal doesn't unmount on close
      closable={true}
    >
      <VehicleHistory
        vehicle_id={vehicle_id}
        form={form}
        inspec_id={inspec_id}
        combinedSummaryFront={combinedSummaryFront} 
        combinedSummaryBack={combinedSummaryBack} 
        InspectionColorPoint={InspectionColorPoint} 
        InspectionTitle={InspectionTitle}
        carnum={carnum}
        vehicleInOutData={vehicleInOutData}
        totalDamage={totalDamage}
        beforeTotalDamage={beforeTotalDamage}
      />
    </MainModal>
  );
});